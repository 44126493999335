import { graphql } from 'gatsby';
import Link from '@components/Link';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import Button from '@atoms/Button/Button';
import Section from '@atoms/Section/Section';
import Footer from '@components/Footer/Footer';
import Header from '@components/Header/Header';
import Layout from '@components/layout';
import Seo from '@components/seo';
import TopBar from '@components/TopBar/TopBar';

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
export default function NotFoundPage() {
    const { t } = useTranslation();

    return (
        <Layout>
            <Seo title={t('404.seo.title')} />

            <div className="global_theme-green">
                <TopBar page="individual" />
                <Section theme="light" spacingBottom="xxl">
                    <Header
                        theme="light"
                        title={t('404.page.title')}
                        text={t('404.page.text')}
                        btnPrimary={
                            <Link to="/">
                                <Button label={t('404.page.cta.label')} />
                            </Link>
                        }
                        btnSecondary={
                            <a href="mailto:care@aepsy.com">
                                <Button
                                    variant="naked"
                                    label={t('button.contact.now')}
                                />
                            </a>
                        }
                    />
                </Section>

                <Footer />
            </div>
        </Layout>
    );
}
